import React from "react";
import { Navbar, Nav, NavDropdown, Dropdown } from "react-bootstrap" ;
import { LinkContainer } from "react-router-bootstrap";
import cookie from "react-cookies" ;

class Header extends React.Component {

	constructor(props, context) {
    super(props, context);

		this.state = {
			menus: null, 
			loaded: false
		}
	}

	componentDidMount = () => {
		this.getMenus()
	}

	componentWillUnmount = () => {
	}
	
	getToken = () => {
		const client_id = process.env.REACT_APP_CLIENT_ID ;

		const cookie_user = 'CognitoIdentityServiceProvider.'+client_id+'.LastAuthUser' ;
		const username = cookie.load( cookie_user ) ;
		const cookie_accessToken = 'CognitoIdentityServiceProvider.'+client_id+'.'+username+'.accessToken' ;
		const accessToken = cookie.load( cookie_accessToken ) ;
		const jwtToken = accessToken ? accessToken : process.env.REACT_APP_JWT_TOKEN ;

		return jwtToken ;
	}

	getMenus = async () => {
		try {
			const menus = "menus" ;
			const jwtToken = this.getToken() ;
			let params = { method: 'GET', headers: { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer ' + jwtToken } } ;
			let response = await fetch( 'https://' + process.env.REACT_APP_APIS_DOMAIN + '/articles/'+menus, params ) ;
			let result = await response.json() ;
			
			this.setState( { menus: result, loaded: true }) ;

			return result ;
		} catch( error ) {
			console.log( "Error getting menus: ", error ) ;
		}
	}

//	handleSelect = eventKey => { 
//		alert(`selected ${eventKey}`);
//		history.push( eventKey ) ;
//	}

	render = () => {
		const { menus, loaded } = this.state ;

		return (
			( loaded 
			?	<Navbar style={{paddingTop:0, paddingBottom:0}} collapseOnSelect bg="dark" variant="dark" fixed="top" expand="lg">
					<LinkContainer to="/">
						<Navbar.Brand href="/">
							{menus.brand}
						</Navbar.Brand>
					</LinkContainer>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="mr-auto">
							{ menus.items.map( ( menu, index ) => {
								return (
									<NavDropdown key={index} title={menu.title} id="basic-nav-dropdown">
										{ menu.items.map( ( item, index ) => {
											return( <Dropdown.Item key={index} href={"/articles/"+item.name}>{item.title}</Dropdown.Item> )
										})}
									</NavDropdown>
								)
							})}
						</Nav>
						<Nav>
	 						<Nav.Link href="/admin">Admin</Nav.Link>
	 						<Nav.Link href="/signout">Signout</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			: null
			)
		)
	}
}

export default Header