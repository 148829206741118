import React from "react";
import {Container} from "react-bootstrap" ;
import cookie from 'react-cookies'

import Header from "../components/adminheader" ;
import Footer from "../components/footer" ;

import './members.css' ;

class Members extends React.Component {

	constructor(props, context) {
    super(props, context);

		this.state = {
			userpool_id: '1mlcm2s5rbsbm8s6pnl95j31i2',
			username: '',
			accessToken: ''
		 }
	}

	componentDidMount() {
		const { userpool_id } = this.state ;
		const cookie_user = 'CognitoIdentityServiceProvider.'+userpool_id+'.LastAuthUser' ;
		const username = cookie.load( cookie_user ) ;
		const cookie_accessToken = 'CognitoIdentityServiceProvider.'+userpool_id+'.'+username+'.accessToken' ;
		const accessToken = cookie.load( cookie_accessToken ) ;

		this.setState( { username: username, accessToken: accessToken } ) ;
	}

	componentWillUnmount() {
  }

	render() {
		const { username} = this.state ;

		return (
 	  	<Container fluid className="app-container">
				<Header />
				<div className="members-container" >
					<h1>{username}</h1>
				</div>
				<Footer />
			</Container>
			);
	}
}

export default Members ;