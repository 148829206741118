import React from "react";
import {Container, Image} from "react-bootstrap" ;
import cookie from 'react-cookies' ;

import { Editor } from '@tinymce/tinymce-react';

import Header from "../components/header" ;
import Footer from "../components/footer" ;

import './home.css' ;

class Home extends React.Component {

	constructor(props, context) {
    super(props, context);

		this.state = {
			loaded: false 
		, article: {} 
		}		 
	}

	componentDidMount() {
		this.getArticle() ;
	}

	componentWillUnmount() {
	}
	
	getToken = () => {
		const client_id = process.env.REACT_APP_CLIENT_ID ;

		const cookie_user = 'CognitoIdentityServiceProvider.'+client_id+'.LastAuthUser' ;
		const username = cookie.load( cookie_user ) ;
		const cookie_accessToken = 'CognitoIdentityServiceProvider.'+client_id+'.'+username+'.accessToken' ;
		const accessToken = cookie.load( cookie_accessToken ) ;
		const jwtToken = accessToken ? accessToken : process.env.REACT_APP_JWT_TOKEN ;

		return jwtToken ;
	}

	getArticle = async () => {
		try {
			const home = "home" ;
			const jwtToken = this.getToken() ;
			let params = { method: 'GET', headers: { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer ' + jwtToken } } ;
			let response = await fetch( 'https://' + process.env.REACT_APP_APIS_DOMAIN + '/articles/'+home, params ) ;
			let article = await response.json() ;
			
			this.setState( { article: article, loaded: true }) ;

			return article ;
		} catch( error ) {
			console.log( "Error getting article: ", error ) ;
		}
	}

	render()
	{
		const { loaded, article } = this.state ;

		return (
			( loaded
			? <Container fluid style={{backgroundColor: 'lightgrey', paddingTop: 70+'px'}}>
					<Container style={{backgroundColor:'white', marginBottom: 20+'px',paddingBottom: 50+'px', paddingTop: 3+'%', paddingLeft: 8+'%', paddingRight: 8+'%' }}>
						<Header />
						<div className="home-container" >
							<Editor className="tutorial-editor"
								value={article.content}
								apiKey='xdlxqnf8mqguhjq4tbtqekuoyct1abgtzzy6x0ow3e0jlizo'
								id='sans-editor'
								init={{
//								height: 80+'vh',
									branding: false,
									toolbar: false,
									menubar: false,
									wordcount: false,
									inline: true,
//								resize: true,
//								autoresize_bottom_margin: 0,
//								plugins: [
//									'advlist autolink lists link image charmap print preview anchor',
//									'searchreplace visualblocks code fullscreen quickbars template',
//									'insertdatetime media table paste code help emoticons ', 
//									'save codesample a11ychecker pagebreak' 
//								],
								// plugins not added - directionality autoresize
								// premium - checklist casechange permanentpen formatpainter pageembed showcomments addcomment
								// cloud - insertfile
//								toolbar: 'save | undo redo | ' +
//												 'bold italic underline strikethrough | ' + 
//												 'fontselect fontsizeselect formatselect | ' + 
//												 'alignleft aligncenter alignright alignjustify | ' +
//												 'outdent indent |  numlist bullist | ' + 
//												 'forecolor backcolor removeformat | ' + 
//												 'pagebreak | charmap emoticons | ' + 
//												 'fullscreen preview print | ' + 
//												 'image media template link anchor codesample | a11ycheck ltr rtl '
//								toolbar:
//									'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image | fullscreen | removeformat | help'
 
								}}
								onEditorChange={this.handleEditorChange}
								disabled={true}
							/>
						</div>
					</Container>
					<Footer />
				</Container>
			: <Container fluid className="home-background">
					<Image className="home-image" src="https://sans-caffeine.com/images/windows-is-loading-files.jpg"/>
				</Container>
			)
		) ;
	}
}

export default Home ;