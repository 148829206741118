import React from 'react' ;
import {Container, Jumbotron, FormControl, ButtonToolbar, Button } from 'react-bootstrap' ;
import { Editor } from '@tinymce/tinymce-react';

import './articles.css' ;
import './prism.css' ;

class ArticleEdit extends React.Component {

	constructor(props, context) {
    super(props, context);

		this.state = {
			dirty: false, 
			article: {...this.props.article} 
		}
	}

	componentDidMount = () => {
//		console.log( "articleview - componentDidMount" ) ;
	}
		
	componentWillUnmount = () => {
 	}

	componentDidUpdate(prevProps, prevState) {
		const old_article = prevState.article ;
		const new_article = { ...this.props.article } ;

//		console.log( "articleview - componentDidUpdate ", old_article, new_article ) ;

		if ( new_article ) {
			if ( ( !old_article ) || ( old_article.id !== new_article.id ) ) {
				this.setState( { article: new_article, dirty: false } ) ;
			}
		} else {
			if ( old_article ) {
				this.setState( { article: null, dirty: false } ) ;
			}
		}
	}


	onSave = async ( event ) => {
		event.preventDefault() ;
		
		const { updateArticle } = this.props ;
		const { article } = this.state ;

//		console.log( "articleview - onSave ", article ) ;

		if ( updateArticle ) {
			const result = await updateArticle( article ) ;
			if ( result ) {
				this.setState( { dirty: false } ) ;
			}
		}
	}

	onCancel = ( event ) => { 
		event.preventDefault() ;

		const { cancelArticle } = this.props ;
		
		if ( cancelArticle ) {
			cancelArticle() ;

			this.setState( { dirty: false } ) ;
		}
	}

	onFieldChange = ( event ) => {
		const { dirty, article }  = this.state ;

		article[event.target.name] = event.target.value ;

		if ( !dirty ) {
			this.setState( { dirty: true } ) ;
		}
	}


	onChange = ( event ) => {
		//		let { album } = this.props ;
		//
		//		album.name = event.target.value ;
			}
		

	handleEditorChange = (newContent, editor) => {
		const { dirty, article } = this.state ; 

		article.content = newContent ;

		if ( !dirty ) {
			this.setState( { dirty: true } ) ;
		}
		
//		this.setState({ dirty: true, article: article });
	}

	render = () => {
		const {article, dirty} = this.state ;

		return (
			( article 
			?	<Container style={{padding:0, margin:0}} fluid>
					<Jumbotron>
						<FormControl id="title"
							defaultValue={article.title}
							placeholder="Untitled"
							plaintext
							readOnly={false}
							aria-label="ArticleTitle"
							aria-describedby="basic-addon1"
							name="title"
							onChange={this.onFieldChange}
						/>
						<ButtonToolbar className="float-right ml-auto justify-content-end">
							<Button className="mr-2" variant="primary" size='sm' onClick={this.onSave} disabled={!dirty}>Save</Button>
							<Button variant="outline-primary" size='sm' onClick={this.onCancel}>Cancel</Button>
						</ButtonToolbar>
				  </Jumbotron>
					<Editor className="author-editor"
							apiKey='xdlxqnf8mqguhjq4tbtqekuoyct1abgtzzy6x0ow3e0jlizo'
							value={article.content}
							init={{
								height: 65+'vh',
								branding: false,
//								toolbar: false,
								browser_spellcheck: true,
								contextmenu: false,
								menubar: true,
								wordcount: false,
								resize: true,
								relative_urls : false,
								remove_script_host : false,
//								document_base_url : "http://sans-caffeine.com",
								image_list: [
									{ title: "Create an AWS Account", value: "/create_account/register/Register 0.png" } 
								,	{ title: "Personal Information", value: "/create_account/register/Register 1.png" } 
								],						
								// plugins not added - directionality autoresize
								// premium - checklist casechange permanentpen formatpainter pageembed showcomments addcomment
								// cloud - insertfile
								plugins: [
									'advlist autolink lists link image charmap print preview anchor',
									'searchreplace visualblocks code fullscreen quickbars template',
									'insertdatetime media table paste code help emoticons ', 
									'save codesample pagebreak' 
								],
								//toolbars not enables - save (uses a form)
								toolbar1: 'undo redo | ' +
												 'bold italic underline strikethrough | ' + 
												 'fontselect fontsizeselect formatselect | ' + 
												 'alignleft aligncenter alignright alignjustify | ' +
												 'outdent indent |  numlist bullist | ' ,
								toolbar2: 
												 'forecolor backcolor removeformat | ' + 
												 'pagebreak | charmap emoticons | ' + 
												 'code fullscreen preview print | ' + 
												 'image media template link anchor codesample | a11ycheck ltr rtl '
							}}
							onEditorChange={this.handleEditorChange}
//							disabled={true}
						/>

				</Container>
			: null 
			)
		) ;
	}
}

export default ArticleEdit ;