import React from 'react' ;
import {Container } from 'react-bootstrap' ;
import cookie from 'react-cookies' ;

import ArticlesView from './articlesview.js' ;
import ArticleEdit from './articleedit.js' ;

import Header from "../components/adminheader" ;
import Footer from "../components/footer" ;

import './articles.css' ;

class Article extends React.Component {

	constructor(props, context) {
    super(props, context);

		this.state = {
			articles: [],
			article: null, 
			loaded: false 
		}
	}

	componentDidMount = () => {
//		const {article, articles} = this.state ;
//		console.log( "articles - componentDidMount ", article, articles.length ) ;

		this.getArticles() ;
	}

	componentWillUnmount() {
//		console.log( "articles - componentWillUnmount" ) ;
  }

	/*
	componentDidUpdate(prevProps, prevState) {
		const { articles, loaded } = this.state ;
//		console.log( "articles - componentDidUpdate ", article, articles.length ) ;
		const { article_id } = this.props.match.params ;

		if ( article_id ) {
			if ( prevState.article ) {
				if ( ( prevState.article.id !== article_id ) && ( loaded ) ) {
					const article = articles.find( ({ id }) => id === article_id )
					if ( article ) {
						this.setState( {article: article} ) ;
					}
				}
			}
		} else if ( prevState.article ) {
			this.setState( { article: null } ) ;
		}
	}
	*/

	getToken = () => {
		const client_id = process.env.REACT_APP_CLIENT_ID ;

		const cookie_user = 'CognitoIdentityServiceProvider.'+client_id+'.LastAuthUser' ;
		const username = cookie.load( cookie_user ) ;
		const cookie_accessToken = 'CognitoIdentityServiceProvider.'+client_id+'.'+username+'.accessToken' ;
		const accessToken = cookie.load( cookie_accessToken ) ;
		const jwtToken = accessToken ? accessToken : process.env.REACT_APP_JWT_TOKEN ;

		return jwtToken ;
	}

	getArticles = async () => {
		try {
			const jwtToken = this.getToken() ;
			let params = { method: 'GET', headers: { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer ' + jwtToken } } ;
			let response = await fetch( 'https://' + process.env.REACT_APP_APIS_DOMAIN + '/articles', params ) ;
			let articles = await response.json() ;
			
			this.setState( { articles: articles, loaded: true }) ;

			return articles ;
		} catch( error ) {
			console.log( "Error getting article: ", error ) ;
		}
	}

	createArticle = async ( article ) => {
		try {
			let jwtToken = this.getToken() ;
			let json = JSON.stringify( article ) ;
			let params = { method: 'POST', headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: 'Bearer ' + jwtToken }, body: json } ;
			let response = await fetch( 'https://' + process.env.REACT_APP_APIS_DOMAIN + '/articles', params ) ;
			let result = await response.json() ;
			
			return result ;
		} catch( error ) {
			console.log( "Error creating article: ", error ) ;
		}
	}

	updateArticle = async ( article ) => {
		try {
			let jwtToken = this.getToken() ;
			let json = JSON.stringify( article ) ;
			let params = { method: 'PUT', headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: 'Bearer ' + jwtToken }, body: json } ;
			let response = await fetch( 'https://' + process.env.REACT_APP_APIS_DOMAIN + '/articles/'+article.id, params ) ;
			let result = await response.json() ;
			
			return result ;
		} catch( error ) {
			console.log( "Error updating article: ", error ) ;
		}
	}

	deleteArticle = async ( article ) => {
		try {
			let jwtToken = this.getToken() ;
			let params = { method: 'DELETE', headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: 'Bearer ' + jwtToken } } ;
			let response = await fetch( 'https://' + process.env.REACT_APP_APIS_DOMAIN + '/articles/'+article.id, params ) ;
			let result = response.ok ;
			
			return result ;
		} catch( error ) {
			console.log( "Error deleting article: ", error ) ;
		}
	}

	onEditArticle = async( index ) => {
		const { articles } = this.state ;

		let article = articles[index] ;

		this.setState( { article: article } ) ;
	}

	onCancelArticle = () => {
		this.setState( { article: null } ) ;
	}

	onAddArticle = async () => {
//		const { articles } = this.state ;

		let article = {} ;

		this.setState( { article: article } ) ;
	}

	onUpdateArticle = async ( new_article ) => {
		const { articles } = this.state ;
//		let { article } = this.state ;

		if ( new_article.id === undefined ) {
			let result = await this.createArticle( new_article ) ;
			if ( result ) {
				articles.push( result ) ;
				this.setState({ articles: articles, article: result } ) ;
				return true ;
			}
		} else {
			let result = await this.updateArticle( new_article ) ;

			if ( result ) {
				const index = articles.map( article => { return article.id ; }).indexOf( new_article.id ) ;
				if ( index !== undefined ) {
					articles[index] = result ;
					this.setState({ articles: articles, article: result }) ;
					return true ;
				} 
			}
		}

		return false ;
	}

	onDeleteArticle = async ( index ) => {
		const { articles } = this.state ;

		const article = articles[index] ;

		let result = await this.deleteArticle( article ) ;
		if ( result ) {	
			articles.splice(index,1) ; 
			this.setState( { articles: articles }) ; 
		}
	}

	render = () => {
		const {articles, article, loaded} = this.state ;

		return (
			<Container fluid style={{ paddingTop:'35px', paddingLeft: 0, paddingRight:0}}>
				<Header />
				<Container className="articles-container" >
					{ loaded 
					? article 
						? <ArticleEdit article={article} updateArticle={this.onUpdateArticle} cancelArticle={this.onCancelArticle}/>
						: <ArticlesView articles={articles} addArticle={this.onAddArticle} editArticle={this.onEditArticle} deleteArticle={this.onDeleteArticle}/>
					: null 
					}
				</Container>
				<Footer />
			</Container>
		) ;
	}
}

export default Article ;

