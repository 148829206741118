import React from "react";
import {Container} from "react-bootstrap" ;

import Header from "../components/header" ;
import Footer from "../components/footer" ;

import './about.css' ;

class About extends React.Component {

	constructor(props, context) {
    super(props, context);

		this.state = {
		 }		 
	}

	componentDidMount() {
	}

	componentWillUnmount() {
  }

	render()
	{
		return (
 	  	<Container fluid style={{ paddingTop:'70px', paddingLeft: 5+'%', paddingRight: 5+'%' }}>
				 <Header />
				 <div className="about-container" >
					 <h1>About</h1>
				 </div>
				 <Footer />
			</Container>
	  );
	}
}

export default About ;