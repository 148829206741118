import React from "react";
import {Container} from "react-bootstrap" ;
//import {LinkContainer} from "react-router-bootstrap" ;

import { Editor } from '@tinymce/tinymce-react';
//import { tinymce } from 'tinymce';

import Header from "../components/header" ;
import Footer from "../components/footer" ;

import "./tutorial.css" ;

class Tutorials extends React.Component {

	constructor(props, context) {
    super(props, context);

		this.state = {
			articles: [],
			loaded: false 
		 }		 
	}

	componentDidMount() {
		this.getArticles() ;
	}

	componentWillUnmount() {
  }

	getArticles = async () => {
		try {
			let params = { method: 'GET', headers: { 'Content-Type': 'application/json; charset=utf-8' } } ;
			let response = await fetch( 'https://' + process.env.REACT_APP_APIS_DOMAIN + '/articles', params ) ;
			let articles = await response.json() ;
			
			this.setState( { articles: articles, loaded: true }) ;

			return articles ;
		} catch( error ) {
			console.log( "Error getting article: ", error ) ;
		}
	}

	handleEditorChange = (newContent, editor) => {
	//	const { content } = this.state ; 
//		content.content = newContent ;
//		this.setState({ dirty: true, content: content });
	}

	render() {
		const { articles, loaded } = this.state ;
		const { content_id } = this.props.match.params ;

		const article = loaded ? articles.find( ({ name }) => name === content_id ) || { title: '', name: '', content: '' } : { title: '', name: '', content: '' }

		return (
			<Container fluid style={{backgroundColor: 'lightgrey', paddingTop: 70+'px'}}>
				<Container style={{backgroundColor:'white', marginBottom: 20+'px',paddingBottom: 50+'px', paddingTop: 3+'%', paddingLeft: 8+'%', paddingRight: 8+'%' }}>
				<Header />
					<div className="author-container" >
					<h1>{article.title}</h1>
					<Editor className="tutorial-editor"
							value={article.content}
							apiKey='xdlxqnf8mqguhjq4tbtqekuoyct1abgtzzy6x0ow3e0jlizo'
							id='sans-editor'
							init={{
//								height: 80+'vh',
								branding: false,
								toolbar: false,
								menubar: false,
								wordcount: false,
								inline: true,
//								resize: true,
//								autoresize_bottom_margin: 0,
//								plugins: [
//									'advlist autolink lists link image charmap print preview anchor',
//									'searchreplace visualblocks code fullscreen quickbars template',
//									'insertdatetime media table paste code help emoticons ', 
//									'save codesample a11ychecker pagebreak' 
//								],
								// plugins not added - directionality autoresize
								// premium - checklist casechange permanentpen formatpainter pageembed showcomments addcomment
								// cloud - insertfile
//								toolbar: 'save | undo redo | ' +
//												 'bold italic underline strikethrough | ' + 
//												 'fontselect fontsizeselect formatselect | ' + 
//												 'alignleft aligncenter alignright alignjustify | ' +
//												 'outdent indent |  numlist bullist | ' + 
//												 'forecolor backcolor removeformat | ' + 
//												 'pagebreak | charmap emoticons | ' + 
//												 'fullscreen preview print | ' + 
//												 'image media template link anchor codesample | a11ycheck ltr rtl '
//								toolbar:
//									'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image | fullscreen | removeformat | help'
 
							}}
							onEditorChange={this.handleEditorChange}
							disabled={true}
						/>
					</div>
					</Container>
				<Footer />
			</Container>
		);
	}
}

export default Tutorials ;