import React from 'react';
import { Container, Row, Col, ButtonToolbar, Button } from "react-bootstrap"
import cookie from "react-cookies" ;

import Header from '../components/header';
import Footer from '../components/footer';

import { Editor } from '@tinymce/tinymce-react';

import './author.css' ;

class Author extends React.Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			dirty: false, 
			content: { content_id: '', content: '' },
			userpool_id: '1mlcm2s5rbsbm8s6pnl95j31i2',
			username: '',
		}
	}

	componentDidMount() {
		const { userpool_id } = this.state ;
		const cookie_user = 'CognitoIdentityServiceProvider.'+userpool_id+'.LastAuthUser' ;
		const username = cookie.load( cookie_user ) ;
		const cookie_accessToken = 'CognitoIdentityServiceProvider.'+userpool_id+'.'+username+'.accessToken' ;
		const accessToken = cookie.load( cookie_accessToken ) ;

		console.log( userpool_id, cookie_user, username, cookie_accessToken, accessToken ) ;

		this.setState( { username: username, accessToken: accessToken } ) ;

		this.getContent() ;
	}

	componentWillUnmount() {
	}

	//check for access token change?
		
	componentDidUpdate(prevProps, prevState) {
		if ( prevProps.match.params.content_id !== this.props.match.params.content_id ) {
			if ( this.props.match.params.content_id ) {
				this.getContent() ;
			}
		}
	}

	getContent = () => {
		const { content_id } = this.props.match.params ;

		var xhr = new XMLHttpRequest();

		xhr.onerrror = function( error ) {
			console.log( "Error getting content", error ) ;
		}

		xhr.onload = function () {
			if (xhr.readyState === 4 && xhr.status === 200) {
				var content = JSON.parse(xhr.responseText);
				this.setState( { content: content } ) ;
			} else {
				console.log( "Error getting content") ;
			}
		}.bind(this) ;

		xhr.open("GET", 'https://api.sans-caffeine.com/v1/content/'+content_id, true);
		xhr.setRequestHeader('Content-type','application/json; charset=utf-8');
		xhr.send() ;

	}

	updateContent = () => {
		const { content_id } = this.props.match.params ;
		const { content, accessToken } = this.state ;
		const json = JSON.stringify( content ) ;

		var xhr = new XMLHttpRequest();

		xhr.onerrror = function( error ) {
			console.log( "Error updating content", error ) ;
		}

		xhr.onload = function () {
			if (xhr.readyState === 4 && xhr.status === 200) {
				var content = JSON.parse(xhr.responseText);
				this.setState( { dirty: false, content: content } ) ;
			} else {
				console.log( "Error updating content") ;
			}
		}.bind(this) ;

		xhr.open("PUT", 'https://api.sans-caffeine.com/v1/content/'+content_id, true);
		xhr.setRequestHeader('Content-type','application/json; charset=utf-8');
		xhr.setRequestHeader('Authorization', accessToken ) ;
		xhr.send(json) ;
	}
 
	handleEditorChange = (newContent, editor) => {
		const { content } = this.state ; 
		content.content = newContent ;
		this.setState({ dirty: true, content: content });
	}

	onSave = () => {
		this.updateContent() ;
	}

	onCancel = () => {
	}

 	render() {
		const { dirty, content, accessToken } = this.state;

		var disableSave ;

		if ( accessToken ) {
			disableSave = !dirty ;
		} else {
			disableSave = true ;
		}
		
		return (
			<Container fluid style={{backgroundColor:'lightgrey'}}>
				<Header />
				<Container style={{backgroundColor:'white', paddingTop: 70+'px', paddingLeft: 6 + '%', paddingRight: 6 + '%', paddingBottom: '10px'}}>
					<div className="author-container" >
					<Row>
						<Col>
							<h1>{content.title}</h1>
						</Col>
						<Col>
								<ButtonToolbar className="float-right">
									<Button style={{margin: 5+'px'}} size='sm' onClick={this.onSave} disabled={disableSave}>Save</Button>
									<Button style={{margin: 5+'px'}} size='sm' onClick={this.onCancel}>Cancel</Button>
					 			</ButtonToolbar>
						 </Col>
					</Row>
					<Editor className="author-editor"
							apiKey='xdlxqnf8mqguhjq4tbtqekuoyct1abgtzzy6x0ow3e0jlizo'
							value={content.content}
							init={{
								height: 80+'vh',
								branding: false,
//								toolbar: false,
								browser_spellcheck: true,
								contextmenu: false,
								menubar: true,
								wordcount: false,
								resize: true,
								relative_urls : false,
								remove_script_host : false,
//								document_base_url : "http://sans-caffeine.com",
								image_list: [
									{ title: "Create an AWS Account", value: "/create_account/register/Register 0.png" } 
								,	{ title: "Personal Information", value: "/create_account/register/Register 1.png" } 
								],						
								// plugins not added - directionality autoresize
								// premium - checklist casechange permanentpen formatpainter pageembed showcomments addcomment
								// cloud - insertfile
								plugins: [
									'advlist autolink lists link image charmap print preview anchor',
									'searchreplace visualblocks code fullscreen quickbars template',
									'insertdatetime media table paste code help emoticons ', 
									'save codesample a11ychecker pagebreak' 
								],
								//toolbars not enables - save (uses a form)
								toolbar1: 'undo redo | ' +
												 'bold italic underline strikethrough | ' + 
												 'fontselect fontsizeselect formatselect | ' + 
												 'alignleft aligncenter alignright alignjustify | ' +
												 'outdent indent |  numlist bullist | ' ,
								toolbar2: 
												 'forecolor backcolor removeformat | ' + 
												 'pagebreak | charmap emoticons | ' + 
												 'code fullscreen preview print | ' + 
												 'image media template link anchor codesample | a11ycheck ltr rtl '
							}}
							onEditorChange={this.handleEditorChange}
//							disabled={true}
						/>
					</div>
					</Container>
				<Footer />
			</Container>
		);
	}
}

export default Author;
