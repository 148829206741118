import React from 'react' ; // react
import { withRouter, Route, Switch } from "react-router-dom" ; //react routing
import {Helmet} from "react-helmet" ;
//import ReactGA from 'react-ga' ; // react - google analystics

import Home from './pages/home.js'
import Tutorials from './pages/tutorials.js'
import Blogs from "./pages/blogs.js"
import About from "./pages/about.js"
import Members from "./pages/members.js"
import Author from "./pages/author.js"
import Articles from "./pages/articles.js"

import './App.css';


class App extends React.Component {

	constructor(props, context) {
		super(props, context);
		
		this.state = {
		} ;
	}

	componentDidMount = async () => {
	}

	handleLogout = async (event) => {
	}

	render = () => {

//		var security = this.state.security ;
		const title       = process.env.REACT_APP_TITLE ;
		const description = process.env.REACT_APP_DESCRIPTION ;

		return (
			<div className="application">
				<Helmet>
					<meta charSet="utf-8" />
					<meta name="description" content={description}/>
					<title>{title}</title>
				</Helmet>
				<Switch>
					<Route exact path="/" component={Home}/>
					<Route exact path="/articles/:content_id" component={Tutorials}/>
					<Route exact path="/articles" component={Tutorials}/>
					<Route exact path="/blogs" component={Blogs}/>
					<Route exact path="/about" component={About}/>
					<Route exact path="/admin" component={Members}/>
					<Route exact path="/admin/edit/:content_id" component={Author}/>
					<Route exact path="/admin/edit" component={Author}/>
					<Route exact path="/admin/articles" component={Articles}/>
				</Switch>
			</div>
		) ;
	}
}

export default withRouter( App ) ;
