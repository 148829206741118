import React from 'react' ;
import {Container, Row, Col, Jumbotron, ButtonToolbar, Button, Modal, InputGroup, FormControl } from 'react-bootstrap' ;
import {withRouter} from 'react-router-dom' ;

import './articles.css' ;

class AddArticle extends React.Component {

	constructor(props, context) {
    super(props, context);

		this.state = {
			article: { name: '', content: '' } 
		}
	}

	onHide = () => {
		const { hide } = this.props ;

		if ( hide ) {
			hide() ;
			this.setState( {article: { name: '', content: '' }} ) ;
		}
	}

	onAdd = () => {
		const { addArticle } = this.props ;
		if ( addArticle ) {
			const { article } = this.state ;
			addArticle( article ) ;
			this.setState( {article: { name: '', content: '' }} ) ;
		}
	}

	handleChange = event => {
		const { article } = this.state ;
		article[event.target.id] = event.target.value ;
		this.setState({ article: article }) ;
	}

	render() {
		const {show} = this.props ;

		return (
			<Modal centered show={show} onHide={this.onHide}>
				<Modal.Header>
					<Modal.Title>Create Article</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				<InputGroup className="mb-3">
			    	<InputGroup.Prepend>
      				<InputGroup.Text id="namelabel">Article Name</InputGroup.Text>
    				</InputGroup.Prepend>
   		 			<FormControl
							id="name"
      				placeholder="name"
      				aria-label="name"
							aria-describedby="namelabel"
							value={this.state.article.name}
							onChange={this.handleChange}
							autoFocus
    				/>
				  </InputGroup>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={this.onAdd}>Add</Button>
					<Button variant="outline-secondary" onClick={this.onHide}>Cancel</Button>
				</Modal.Footer>
			</Modal>
		) ;
	}
}

class ArticlesView extends React.Component {

	constructor(props, context) {
    super(props, context);

		this.state = {
			showAddArticle: false
		}
	}

	onAdd = () => { 
		this.setState( { showAddArticle: true } ) ;
	}

	onHide = () => { 
		this.setState( { showAddArticle: false } ) ;
	}

	onAddArticle = ( event ) => {
		event.preventDefault() ;

		const { addArticle } = this.props ;
		
		if ( addArticle ) {
			addArticle() ;
		}
	}

	onEditArticle = ( event ) => {
		event.preventDefault() ;

		const { editArticle } = this.props ;

		if ( editArticle ) {
			editArticle( event.target.id ) ;
		}
	}

	onDeleteArticle = ( event ) => {
		event.preventDefault() ;

		const { deleteArticle } = this.props ;

		if ( deleteArticle ) {
			deleteArticle( event.target.id ) ;
		}
	}

	render = () => {
		const {showAddArticle} = this.state ;
		const {articles} = this.props ;

		return (
			<Container fluid style={{paddingRight:'2%'}}>
				<Row>
				<Col className="article-sidebar" lg={2} md={2} sm={2} xs={2}>
					<h1>Sidebar</h1>
					<p>This will eventually hold the folder view</p>
				</Col>
				<Col lg={10} md={10} sm={10} xs={10}>
				<Row>
				<Jumbotron style={{width:'100%'}}>
					<h1>Articles</h1>
					<ButtonToolbar className="float-right ml-auto justify-content-end">
						<Button variant="primary" size='sm' onClick={this.onAddArticle}>Add Article</Button>
					</ButtonToolbar>
				</Jumbotron>
				</Row>
					{ articles.map( ( article, index ) => {
						return (
								<Row key={index} className="article-card">
									<Col xs={10}>
											<h5>{article.title}</h5>
											<p>{article.description||"Article Description"}</p>
										</Col>
										<Col>
											<ButtonToolbar className="float-right">
												<Button id={index} onClick={this.onDeleteArticle} variant="danger" size="sm" className="mb-2 mr-2">Delete</Button>
												<Button id={index} onClick={this.onEditArticle} variant="primary" size="sm" className="mb-2">Edit</Button>
											</ButtonToolbar>
										</Col>									
								</Row>
						) ;
					})}
				</Col>
				</Row>
				<AddArticle show={showAddArticle} hide={this.onHide} addArticle={this.onAddArticle} />
				<Row>
					<p>You can't see me</p>
				</Row>
			</Container>
		) ;
	}
}

export default withRouter( ArticlesView ) ;